<template>
  <div class="my-vehicles">
    <v-card class="pa-0 pb-10" tile>
      <Loader v-if="loading"/>
      <template v-else>
        <v-card-title>
          <h4 class="primary--text">
            {{ $t("loads.title") }}
          </h4>
          <v-spacer />
          <v-btn color="primary" outlined :class="{ 'mr-4': !$vuetify.breakpoint.xsOnly, 'mb-2': $vuetify.breakpoint.smAndDown }"
            @click="$router.push({ name: 'AddVehicle' })">
            {{ $t("myVehicle.add") }}
          </v-btn>
          <v-btn
            v-if="showAddFreightButton"
            color="primary"
            outlined
            @click="$router.push({ name: 'AddFreight' })">
            {{ $t("myVehicle.addFracht") }}
          </v-btn>
        </v-card-title>
        <Tabs class="mb-4" add-new />
        <Filters v-if="displayFilters" class="mb-4" />
        <Loader v-if="$store.getters['market/loading']" />
        <v-card-text v-else class="pa-0">
        <real-time-data-table ref="dataTable" :headers="headers" endpoint="/FreightOrder/GetFiltered" :item-class="itemRowBackground" :sort-by="['publishDate']" :sort-desc="[true]" :showTotal="displayFilters" @click:row="openDetails($event)">

          <template v-slot:item.loadingPlaces="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex align-center">
                <CountryFlag :country='item.loadingPlaces[0].country' class="mr-1"/>
                <div class="d-flex flex-column">
                  <span>
                    {{ item.loadingPlaces[0].country.toUpperCase() }}, {{ item.loadingPlaces[0].place.postalCode }} <br/>
                    {{ item.loadingPlaces[0].place.city }}
                  </span>
                  <div class="lightText--text">
                    <span>{{ item.loadingPlaces[0].date | dateFormat }} </span>
                    <span v-if="item.loadingPlaces[0].from && item.loadingPlaces[0].to">{{ item.loadingPlaces[0].from }} - {{ item.loadingPlaces[0].to }}</span>
                  </div>
                  <div v-if="item.loadingDistanceFromFilter">
                    <span>{{ item.loadingDistanceFromFilter }}</span>
                  </div>
                </div>
              </div>
              <v-tooltip v-if="item.loadingPlaces.length > 1" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="more-data ml-2"> +{{ item.loadingPlaces.length - 1 }} </div>
                </template>
                <div v-for="(location, index) in item.loadingPlaces" :key="index">
                  <div v-if="index !== 0" class="d-flex align-center" style="line-height: 1.25">
                    <CountryFlag :country="location.country" class="mr-1" />
                    <div class="d-flex flex-column">
                      <span>{{ location.country.toUpperCase() }}, {{ location.place.postalCode }} {{ location.place.city }}</span>
                      <span>{{ location.date | dateFormat }}<span v-if="location.from && location.to"> {{ location.from }} - {{ location.to }}</span></span>
                    </div>
                  </div>
                </div>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.unloadingPlaces="{ item }">
            <div v-if="item.unloadingPlaces.length > 0" class="d-flex align-center">
              <div class="d-flex align-center">
                <CountryFlag :country='item.unloadingPlaces[0].country' class="mr-1"/>
                <div class="d-flex flex-column">
                  <div>
                    <span>
                      {{ item.unloadingPlaces[0].country.toUpperCase() }}, {{ item.unloadingPlaces[0].place.postalCode }}<br/>
                      {{ item.unloadingPlaces[0].place.city }}
                    </span>
                    <div class="lightText--text">
                      <span>{{ item.unloadingPlaces[0].date | dateFormat }} </span>
                      <span v-if="item.unloadingPlaces[0].from && item.unloadingPlaces[0].to">{{ item.unloadingPlaces[0].from }} - {{ item.unloadingPlaces[0].to }}</span>
                    </div>
                    <div v-if="item.unloadingDistanceFromFilter">
                      <span>{{ item.unloadingDistanceFromFilter }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <v-tooltip v-if="item.unloadingPlaces.length > 1" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="more-data ml-2">+{{ item.unloadingPlaces.length - 1 }}</div>
                </template>
                <div v-for="(location, index) in item.unloadingPlaces" :key="index">
                  <div v-if="index !== 0" class="d-flex align-center" style="line-height: 1.25">
                    <CountryFlag :country="location.country" class="mr-1" />
                    <div class="d-flex flex-column">
                      <span>{{ location.country.toUpperCase() }}, {{ location.place.postalCode }} {{ location.place.city }}</span>
                      <span>{{ location.date | dateFormat }} <span v-if="location.from && location.to">{{ location.from }} - {{ location.to }}</span></span>
                    </div>
                  </div>
                </div>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.freightDetails="{ item }">
            <div class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <template v-if="item.type.length === 1" >
                      <DisplayIcon v-if="item.type[0].icon" :icon-name="item.type[0].icon" size="26" />
                      <v-icon v-else>$truck</v-icon>
                    </template>
                    <DisplayIcon v-if="item.type.length > 1" :icon-name="'__icon_MANY_TYPES.svg'" size="26" />
                  </div>
                </template>
                <p class="ma-0">
                  <span v-for="(x, index) in item.type" :key="index">{{ x.name }} </span>
                </p>
              </v-tooltip>
              <div class="d-flex flex-column ml-2">
                <p class="mb-0">{{ item.vehicleDetails }}</p>
                <p class="mb-0">{{ item.vehicleDetailsSecondLine }}</p>
              </div>
            </div>
          </template>

          <template v-slot:item.price="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column w-100">
                <p class="mb-0 text-center">{{ item.paymentDetails }}</p>
                <p class="mb-0 text-center">{{ item.paymentDetailsSecondLine }}</p>
              </div>
            </div>
          </template>

          <template v-slot:item.publishDate="{ item }">
            <div class="text-center">{{ item.publishDate | dateFormat('DD.MM.YYYY') }} <br/>
            {{ item.publishDate | dateFormat('HH:mm') }}</div>
          </template>

          <template v-slot:item.publisher="{ item }">
            <div class="d-flex align-center cursor-pointer">
              <StatusAvatar :userId="item.publisher.id" :size="26" showStatus class="mr-2"></StatusAvatar>
              <div class="d-flex flex-column">
                <div>{{ item.publisher.name }} {{ item.publisher.surname }}</div>
                <v-tooltip bottom min-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="lightText--text" v-bind="attrs" v-on="on">{{ item.publisher.company.name | trimText(20) }}</div>
                  </template>
                  <v-row class="mt-1">
                    <v-col cols="6">
                      <p>{{ item.publisher.company.name }}<br />
                      NIP: {{ item.publisher.company.vatNumber }}</p>
                      <p>{{ $t('offerDetails.address') }}:<br />
                      {{ item.publisher.company.street }}<br />
                      {{ item.publisher.company.zipCode }}, {{ item.publisher.company.city }}</p>
                    </v-col>
                    <v-col cols="6" v-if="!$useFeatureFlag(featureFlagList.DISABLE_REVIEWS)">
                      {{ $t('offerDetails.averageRating') }}:
                      <v-rating length="5" size="16" color="accent" :value="item.publisher.company.averageRating" class="mb-4"></v-rating>
                      {{ $t('offerDetails.paymentRating') }}:
                      <v-rating length="5" size="16" color="accent" :value="item.publisher.company.averagePaymentRating"></v-rating>
                    </v-col>
                  </v-row>
                </v-tooltip>
              </div>
            </div>
          </template>

          <template v-slot:item.conversation="{ item }">
            <v-hover v-if="$store.getters.userCommonData.id !== item.publisher.id" v-slot="{ hover }">
              <v-icon :color="hover ? 'primary' : ''" icon @click.stop="startConversationWithUser(item)" size="18">$conversation</v-icon>
            </v-hover>
          </template>

          <template v-slot:item.mail="{ item }">
            <DataTablePopupCell v-if="$store.getters.userCommonData.id !== item.publisher.id" :subject="item" :email="item.publisher.email"
            :clickType="ClickEmail" :orderId="item.id" >
          </DataTablePopupCell>
          </template>

          <template v-slot:item.phone="{ item }">
            <DataTablePopupCell v-if="$store.getters.userCommonData.id !== item.publisher.id" :phone="item.publisher.phone"
             :clickType="ClickPhone" :orderId="item.id" >
            </DataTablePopupCell>
          </template>

          <template v-slot:item.private="{ item }">
            <v-icon v-if="item.isPrivate" size="18">$private</v-icon>
          </template>

          <template v-slot:item.hidden="{ item }">
            <v-icon v-if="item.isHidden" size="18">$hidden</v-icon>
          </template>

          <template v-slot:item.actions="{item}">
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-icon :color="hover ? 'primary' : 'separator'" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </v-hover>
              </template>
              <v-list class="py-0">
                <v-list-item v-if="!item.observed" link @click="observeHandle(item)">
                  <v-list-item-title>
                    {{ $t('marketListOptions.addObserve') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.observed" link @click="unobserveHandle(item)">
                  <v-list-item-title>
                    {{ $t('marketListOptions.removeObserve') }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider class="mx-4"></v-divider>
                <v-list-item link @click="openDetails(item)">
                  <v-list-item-title>
                    {{ $t('marketListOptions.details') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary">{{ $t("users.slotNoData") }}</v-btn>
          </template>

        </real-time-data-table>
        </v-card-text>
      </template>
    </v-card>
    <OfferDetailsDialog
      v-if="vehicleId"
      :value="offerDetailsActive"
      :itemId="vehicleId"
      @closed="closeOfferDetailsDialog"
    />
  </div>
</template>

<script>
import RealTimeDataTable from '@/components/RealTimeDataTable'
import StatusAvatar from '@/components/StatusAvatar'
import CountryFlag from 'vue-country-flag'
import Tabs from '@/views/Market/components/Tabs'
import Filters from '@/views/Market/components/Filters'
import { FREIGHT } from '@/types/order-types'
import { marketStart, marketStop } from '@/signalr/market'
import Loader from '@/components/Loader'
import * as types from '@/store/modules/market/market-mutation-types'
import DisplayIcon from '@/components/icons/DisplayIcon'
import OfferDetailsDialog from './dialogs/OfferDetailsDialog.vue'
import Service from '@/services'
import { catchMarketError } from '@/utils/market'
import { startConversation } from '@/signalr/chat'
import DataTablePopupCell from '@/components/DataTablePopupCell'
import { MainOrderFreight } from '@/types/main-order-types.js'
import { Phone as ClickPhone, Email as ClickEmail, Chat as ClickChat } from '@/types/click-order-types.js'
import { FEATURE_FLAGS } from '@/utils/featureFlagList'
import { shouldShowAddFreightButton } from '@/utils/helpers'

export default {
  name: 'Loads',
  components: {
    RealTimeDataTable,
    StatusAvatar,
    CountryFlag,
    Tabs,
    Filters,
    Loader,
    OfferDetailsDialog,
    DisplayIcon,
    DataTablePopupCell
  },
  data () {
    return {
      loading: true,
      offerDetailsActive: false,
      vehicleId: null,
      show: true,
      headers: [
        {
          text: this.$t('marketTableHeader.load'),
          align: 'start',
          value: 'loadingPlaces'
        },
        {
          text: this.$t('marketTableHeader.unload'),
          align: 'start',
          value: 'unloadingPlaces'
        },
        {
          text: this.$t('marketTableHeader.freightDetails'),
          align: 'start',
          value: 'freightDetails',
          sortable: false
        },
        {
          text: this.$t('marketTableHeader.price'),
          align: 'center',
          value: 'price',
          width: '150',
          sortable: false
        },
        {
          text: this.$t('marketTableHeader.publishDate'),
          align: 'center',
          value: 'publishDate'
        },
        {
          text: this.$t('marketTableHeader.publisher'),
          align: 'start',
          value: 'publisher',
          sortable: false
        },
        {
          value: 'conversation',
          width: '5',
          sortable: false
        },
        {
          value: 'phone',
          width: '5',
          sortable: false
        },
        {
          value: 'mail',
          width: '5',
          sortable: false
        },
        {
          value: 'private',
          width: '5',
          sortable: false
        },
        {
          value: 'hidden',
          width: '5',
          sortable: false
        },
        {
          text: '',
          align: 'end',
          width: '20',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  computed: {
    featureFlagList () {
      return FEATURE_FLAGS
    },
    showAddFreightButton () {
      return shouldShowAddFreightButton(
        this.featureFlagList,
        this.$useFeatureFlag,
        this.$permission
      )
    },
    displayFilters () {
      const currentTab = this.$store.getters['market/currentTab']

      if (!currentTab) {
        return false
      }

      const hardcodedKey = currentTab.hardcodedKey
      return hardcodedKey !== 'FreightObserved' && hardcodedKey !== 'AllFreights'
    }
  },
  beforeCreate () {
    this.$store.commit(`market/${types.SET_ORDER_TYPE}`, FREIGHT)
  },
  async created () {
    this.MainOrderFreight = MainOrderFreight
    this.ClickPhone = ClickPhone
    this.ClickEmail = ClickEmail

    try {
      await this.$store.dispatch('market/fetchFilters')
    } catch (e) {
    } finally {
      this.loading = false
    }
  },
  async mounted () {
    await marketStart()
  },
  beforeDestroy () {
    marketStop()
  },
  methods: {
    itemRowBackground (item) {
      return !item.checked ? 'bgNew' : 'bgRow'
    },
    openDetails (item) {
      this.$store.commit('market/MARK_SHOW_OFFER', item)
      this.vehicleId = item.id
      this.offerDetailsActive = true
    },
    async observeHandle (order) {
      try {
        await Service.post('/FreightOrder/Observe', { orderId: order.id })
        await this.$store.dispatch(
          'setSuccessNotification',
          `${this.$t('market.observedOrder')}`)
      } catch (e) {
        await catchMarketError(e, true)
      }
      order.observed = true
    },
    async unobserveHandle (order) {
      try {
        await Service.post('/FreightOrder/Unobserve', { orderId: order.id })
        await this.$store.dispatch(
          'setSuccessNotification',
          `${this.$t('market.unobservedOrder')}`)
      } catch (e) {
        await catchMarketError(e, true)
      }
      order.observed = false
    },
    closeOfferDetailsDialog () {
      this.offerDetailsActive = false
      this.vehicleId = null
    },
    async startConversationWithUser (row) {
      const data = {
        orderId: row.id,
        clickType: ClickChat
      }

      await Service.post('/order/userorderclickstatistic', data)
      startConversation(row.publisher.id, row.id)
    }
  }
}
</script>
