import store from '@/store'
import i18n from '@/i18n'
import vuetify from '../plugins/vuetify'
import { updateFavicon } from '../utils/updateFavicon'

export const guardLogin = (to, from, next) => {
  if (!localStorage.getItem('token')) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

export const guardGuest = (to, from, next) => {
  if (localStorage.getItem('token')) {
    next({ name: 'Home' })
  } else {
    next()
  }
}

export const guardPermission = (to, from, next) => {
  const permission = store.getters.permissions.find(item => item.name === to.meta.permission)
  if (!permission || !permission.active) {
    next({ name: 'Home' })
  } else {
    next()
  }
}

export const guardFeatureFlag = (to, from, next) => {
  const featureFlag = to.meta.featureFlag

  if (!featureFlag) {
    console.warn('Feature flag not provided in route meta.')
    next({ name: 'Home' })
    return
  }

  const featureFlagEnabled = store.getters.featureFlags.find(
    (item) => item.name === featureFlag
  )

  if (featureFlagEnabled?.active) {
    next({ name: 'Home' })
    return
  }
  next()
}

export const guardPermissionAndFeatureFlag = (to, from, next) => {
  const featureFlag = to.meta.featureFlag
  const permissionKey = to.meta.permission

  if (!featureFlag || !permissionKey) {
    console.warn('Feature flag or permission not provided in route meta.')
    next({ name: 'Home' })
    return
  }

  const featureFlagEnabled = store.getters.featureFlags.find(
    (item) => item.name === featureFlag
  )
  const permissionActive = store.getters.permissions.some(item => item.name === permissionKey && item.active)

  if (!featureFlagEnabled.active || permissionActive) {
    next() // Allow access if feature flag is disabled or permission is active
    return
  }

  // Feature flag is enabled and permission is inactive, block access
  next({ name: 'Home' })
}

export const guardRole = (to, from, next) => {
  const role = to.meta.roles.includes(store.getters.role)
  if (!role) {
    next({ name: 'Home' })
  } else {
    next()
  }
}

export const guardRoleAndPermission = (to, from, next) => {
  const role = to.meta.roles.includes(store.getters.role)
  if (!role) {
    next({ name: 'Home' })
  } else {
    const permission = store.getters.permissions.find(item => item.name === to.meta.permission)
    if (!permission || !permission.active) {
      next({ name: 'Home' })
    } else {
      next()
    }
  }
}

export const beforeEach = async (to, from, next) => {
  if (store.getters.loading) {
    await store.dispatch('initApp')
    const currentTheme = store.getters['theme/currentTheme']
    vuetify.framework.theme.themes.light = currentTheme
    next()
  } else {
    next()
  }
}

export const afterEach = to => {
  const tenantName = store.getters.getTenant.name
  const displayName = store.getters.getTenant.displayName || 'Spedimo'
  document.title = `${displayName} - ` + i18n.t(`documentTitle.${to.name.toLowerCase()}`) || ''

  updateFavicon(tenantName)
}
