export function getDomainMap (domainMapping) {
  return domainMapping.split(',').reduce((map, entry) => {
    const indexOfColon = entry.indexOf(':')
    if (indexOfColon > -1) {
      const key = entry.substring(0, indexOfColon).trim()
      const value = entry.substring(indexOfColon + 1).trim()
      map[key] = value
    }
    return map
  }, {})
}
