<template>
  <v-navigation-drawer
    ref="navigationDrawer"
    v-model="menuDrawer" color="white" class="elevation-2" :mini-variant.sync="mini" mini-variant-width="60" width="180" clipped app mobile-breakpoint="1500"
  >
    <v-list id="nav_menu" class="pt-0">
      <template v-for="(item, i) in items">
        <v-list-item v-if="item.permissions && !item.children" :key="i" :to="item.to" active-class="white--text primary active_nav">
          <v-list-item-icon v-if="item.icon" :class="!mini ? 'mr-4' : ''">
            <v-icon size="26">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="item.title">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-else-if="item.permissions && item.children && item.children.length > 0 && !mini"
          v-model="item.active" :key="i" active-class="white--text primary active_nav">
          <template v-slot:activator>
            <v-list-item-icon v-if="item.icon" :class="!mini ? 'mr-4' : ''">
              <v-icon size="26">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="item.title">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="child in item.children">
            <v-list-item v-if="child.permissions" :key="child.title" :to="child.to" class="subnav" active-class="primary--text active_subnav">
              <v-list-item-icon :class="!mini ? 'mr-4' : ''">
                <v-icon size="26">{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-menu v-else-if="item.permissions && item.children && item.children.length > 0 && mini" :key="i" open-on-hover bottom offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon v-if="item.icon">
                <v-icon size="26">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list width="200" class="py-0">
            <template v-for="child in item.children">
              <v-list-item v-if="child.permissions" :key="child.title" :to="child.to" active-class="subnav_active">
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item>
              <v-divider v-if="child.permissions" :key="child.title" class="mx-4" style="margin-top: -1px"></v-divider>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-list>
    <template v-slot:append>
      <v-divider class="mx-4 mt-auto" />
      <v-list dense>
        <v-list-item @click="mini = !mini">
          <v-list-item :class="!mini ? 'pr-0' : ''">
            <v-list-item-content>
              <div>
                <v-icon size="20" v-if="mini" color="primary" style="transform: rotate(-180deg)">$arrowLeft</v-icon>
                <v-icon size="20" v-else color="primary" class="float-right">$arrowLeft</v-icon>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { FEATURE_FLAGS } from '@/utils/featureFlagList'

export default {
  name: 'Sidebar',
  props: {
    burgerMenuDrawer: {
      Type: Boolean,
      Required: true
    }
  },
  data () {
    return {
      mini: false
    }
  },
  mounted () {
    this.$watch(
      () => {
        return this.$refs.navigationDrawer.isActive
      },
      (val) => {
        if (!val) {
          this.$emit('burger-menu-drawer-change')
        }
      }
    )
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      isCustomerService: 'isCustomerService',
      isOwner: 'isOwner',
      isManager: 'isManager',
      isWorker: 'isWorker',
      isMarketUser: 'isMarketUser'
    }),
    featureFlagList () {
      return FEATURE_FLAGS
    },
    items () {
      return [
        {
          title: this.$t('sidebar.dashboard'),
          icon: '$dashboard',
          to: '/customerService/dashboard',
          permissions: this.isAdmin || this.isCustomerService
        },
        {
          title: this.$t('sidebar.dashboard'),
          icon: '$dashboard',
          to: '/',
          permissions: !(this.isAdmin || this.isCustomerService)
        },
        {
          title: this.$t('sidebar.market.group'),
          icon: '$market',
          to: '/market/',
          permissions: this.isMarketUser,
          active: !!this.$route.path.startsWith('/market'),
          children: [
            {
              title: this.$t('sidebar.market.loads'),
              to: '/market/loads',
              permissions: true
            },
            {
              title: this.$t('sidebar.market.vehicles'),
              to: '/market/vehicles',
              permissions: true
            },
            {
              title: this.$t('sidebar.market.myFreights'),
              to: '/market/frachts',
              permissions: true
            },
            {
              title: this.$t('sidebar.market.myVehicles'),
              to: '/market/my-vehicles',
              permissions: true
            }
          ]
        },
        {
          title: this.$t('sidebar.workers'),
          icon: 'uil uil-user-square',
          to: '/customerService/employees/',
          permissions: this.isAdmin
        },
        {
          title: this.$t('sidebar.users'),
          icon: 'uil uil-users-alt',
          to: '/customerService/impersonation/',
          permissions: this.isAdmin || this.isCustomerService
        },
        {
          title: this.$t('sidebar.issues.group'),
          icon: 'uil uil-clipboard-notes',
          to: '/customerService/issues/',
          permissions: this.$permission('IssuesModuleVisibility'),
          active: !!this.$route.path.startsWith('/customerService/issues'),
          children: [
            {
              title: this.$t('sidebar.issues.current'),
              to: '/customerService/issues/activeTab/0',
              permissions: true
            },
            {
              title: this.$t('sidebar.issues.waiting'),
              to: '/customerService/issues/activeTab/1',
              permissions: true
            },
            {
              title: this.$t('sidebar.issues.archive'),
              to: '/customerService/issues/activeTab/2',
              permissions: true
            },
            {
              title: this.$t('sidebar.issues.drafts'),
              to: '/customerService/issues/activeTab/3',
              permissions: true
            }
          ]
        },
        {
          title: this.$t('sidebar.companiesIssues.group'),
          icon: 'uil uil-list-ul',
          to: '/customerService/companiesIssues/',
          permissions: this.$permission('IssuesModuleVisibility'),
          active: !!this.$route.path.startsWith(
            '/customerService/companiesIssues'
          ),
          children: [
            {
              title: this.$t('sidebar.companiesIssues.current'),
              to: '/customerService/companiesIssues/activeTab/0',
              permissions: true
            },
            {
              title: this.$t('sidebar.companiesIssues.waiting'),
              to: '/customerService/companiesIssues/activeTab/1',
              permissions: true
            },
            {
              title: this.$t('sidebar.companiesIssues.rejected'),
              to: '/customerService/companiesIssues/activeTab/2',
              permissions: this.$permission('ViewRejectedCompanies')
            }
          ]
        },
        {
          title: this.$t('sidebar.companies.group'),
          icon: '$clients',
          to: '/customerService/companies',
          permissions: this.$permission('CompanyModuleVisibility')
        },
        {
          title: this.$t('sidebar.myCompany'),
          icon: '$company',
          to: '/my-company',
          permissions: this.isOwner || this.isManager
        },
        {
          title: this.$t('sidebar.myCompany'),
          icon: '$company',
          to: `/company-details/${this.$store.getters.userCommonData.companyId}`,
          permissions: this.isWorker
        },
        {
          title: this.$t('sidebar.companies.group'),
          icon: '$clients',
          to: '/companies/',
          permissions: this.isMarketUser
        },
        {
          title: this.$t('reviews.title'),
          icon: '$rating',
          to: '/reviews',
          permissions: this.isMarketUser && !this.$useFeatureFlag(this.featureFlagList.DISABLE_REVIEWS)
        },
        {
          title: this.$t('sidebar.settings.group'),
          icon: '$settings',
          to: '/settings/',
          permissions: this.isMarketUser,
          active: !!this.$route.path.startsWith('/settings') || !!this.$route.path.startsWith('/chat'),
          children: [
            {
              title: this.$t('sidebar.settings.profile'),
              to: '/settings/profile',
              permissions: true
            },
            {
              title: this.$t('sidebar.settings.system'),
              to: '/customerService/settings/system',
              permissions: this.$permission('CompanyInvite') || this.$permission('SystemLogs')
            },
            {
              title: this.$t('sidebar.chat.group'),
              to: '/chat/settings',
              permissions: this.isMarketUser
            },
            {
              title: this.$t('sidebar.chat.blockedUsers'),
              to: '/chat/blocked-users',
              permissions: this.isMarketUser
            }
          ]
        },
        {
          title: this.$t('sidebar.settings.group'),
          icon: '$settings',
          to: '/settings/',
          permissions: !this.isMarketUser,
          active: !!this.$route.path.startsWith('/settings') || !!this.$route.path.startsWith('/customerService'),
          children: [
            {
              title: this.$t('sidebar.settings.profile'),
              to: '/settings/profile',
              permissions: true
            },
            {
              title: this.$t('sidebar.settings.system'),
              to: '/customerService/settings/system',
              permissions: this.$permission('CompanyInvite') || this.$permission('SystemLogs')
            }
          ]
        },
        {
          title: this.$t('sidebar.chat.archive'),
          icon: '$archive',
          to: '/archive',
          permissions: !this.$store.getters.isDriver && this.isMarketUser
        },
        {
          title: this.$t('sidebar.chat.group'),
          icon: '$archive',
          to: '/chat/',
          permissions: !this.$store.getters.isDriver && !this.isMarketUser,
          active: !!this.$route.path.startsWith('/chat') || !!this.$route.path.startsWith('/archive'),
          children: [
            {
              title: this.$t('sidebar.chat.settings'),
              to: '/chat/settings',
              permissions: true
            },
            {
              title: this.$t('sidebar.chat.archive'),
              to: '/archive',
              permissions: true
            },
            {
              title: this.$t('sidebar.chat.archiveBOK'),
              to: '/chat/archive-bok',
              permissions: this.$permission('ConversationArchive')
            },
            {
              title: this.$t('sidebar.chat.blockedUsers'),
              to: '/chat/blocked-users',
              permissions: true
            }
          ]
        },
        {
          title: this.$t('sidebar.helpdesk'),
          icon: 'uil uil-comment-question',
          to: '/helpdesk',
          permissions: this.$permission('HelpdeskChat')
        },
        {
          title: this.$t('sidebar.statistics'),
          icon: 'uil uil-chart',
          to: '/statistics',
          permissions: this.$permission('SystemStats')
        },
        {
          title: this.$t('sidebar.reports'),
          icon: 'uil uil-grid',
          to: '/reports',
          permissions: this.$permission('CompanyReportGeneration')
        },
        {
          title: this.$t('cargoPlanner.title'),
          icon: 'uil uil-border-out',
          to: '/cargoPlanner',
          permissions: this.isMarketUser
        }
      ]
    },
    menuDrawer: {
      get () {
        return this.burgerMenuDrawer
      },
      set () {
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn_outlined {
  border: 1px solid var(--v-primary-base);
  border-radius: 4px;
}

.active_nav .v-icon {
  color: var(--v-accent-base)
}

.active_subnav {
  font-weight: bold;
  background-color: var(--v-deal-base);
}
</style>
