var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-5"},[_c('div',{staticClass:"d-flex align-center mb-6"},[_c('h2',{staticClass:"text-subtitle-1 font-weight-bold mr-4"},[_vm._v(" "+_vm._s(_vm.$t("companies.companiesList"))+" ")]),_c('div',[_c('SearchInput',{model:{value:(_vm.searchInputValue),callback:function ($$v) {_vm.searchInputValue=$$v},expression:"searchInputValue"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-select',{attrs:{"items":_vm.salesUsers,"item-text":"name","clearable":"","item-value":"id"},model:{value:(_vm.salesmanFilter),callback:function ($$v) {_vm.salesmanFilter=$$v},expression:"salesmanFilter"}})],1)],1)],1),_c('data-table',{ref:"dataTable",attrs:{"headers":_vm.filteredHeaders,"show-expand":"","single-expand":"","serverPagination":"","endpoint":"CustomerService/GetCurrentCompanies","additionalParams":{ salesman: _vm.salesmanFilter},"search":_vm.searchInputValue},on:{"item-expanded":_vm.loadCompanyUsers},scopedSlots:_vm._u([{key:"item.documentsState",fn:function(ref){
var item = ref.item;
return [(_vm.documentsNeedAction(item.documentsState))?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","color":"stateChange"}},[_vm._v(" "+_vm._s(_vm.getDocumentState(item.documentsState))+" ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","color":"active"}},[_vm._v(" "+_vm._s(_vm.getDocumentState(item.documentsState))+" ")])]}},{key:"item.averageRating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"background-color":"amber accent-4","color":"amber accent-4","length":"5","readonly":"","size":"16","value":item.reviews.averageRating}}),_c('b',[_vm._v(" "+_vm._s(item.reviews.averageRating)+" ")]),_vm._v(" ("+_vm._s(item.reviews.numberOfReviews)+") ")]}},{key:"item.dataChanges",fn:function(ref){
var item = ref.item;
return [(item.dataChanges)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","color":"stateChange"}},[_vm._v(" "+_vm._s(_vm.$t("companies.update"))+" ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","color":"active"}},[_vm._v(" "+_vm._s(_vm.$t("companies.upToDate"))+" ")])]}},{key:"item.acceptedBy",fn:function(ref){
var item = ref.item;
return [(item.acceptedBy)?_c('div',[_vm._v(" "+_vm._s(item.acceptedBy.name)+" "+_vm._s(item.acceptedBy.surname)+" ")]):_vm._e()]}},{key:"item.salesman",fn:function(ref){
var item = ref.item;
return [(item.salesman)?_c('div',[_vm._v(" "+_vm._s(item.salesman.name)+" "+_vm._s(item.salesman.surname)+" ")]):_vm._e()]}},{key:"item.dateOfAcceptation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfAcceptation))+" ")]}},{key:"item.isIntegrationApiEnabled",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"d-flex align-end",attrs:{"hide-details":"","outlined":"","disabled":!_vm.$store.getters.isAdmin},on:{"change":function($event){return _vm.integrationApiEnabledUpdated(item.id, item.isIntegrationApiEnabled)}},model:{value:(item.isIntegrationApiEnabled),callback:function ($$v) {_vm.$set(item, "isIntegrationApiEnabled", $$v)},expression:"item.isIntegrationApiEnabled"}})]}},{key:"item.isReportTabEnabled",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"d-flex align-end",attrs:{"hide-details":"","outlined":"","disabled":!_vm.$store.getters.isAdmin},on:{"change":function($event){return _vm.reportTabEnabledUpdated(item.id, item.isReportTabEnabled)}},model:{value:(item.isReportTabEnabled),callback:function ($$v) {_vm.$set(item, "isReportTabEnabled", $$v)},expression:"item.isReportTabEnabled"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","color":"active"}},[_vm._v(" "+_vm._s(_vm.getUserStatus(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(item.dataChanges && _vm.$permission('CompanyUpdateRequestsManagement'))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.verify(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("companies.verifyProfile"))+" ")])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleCompanyClick(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("companies.profile"))+" ")])],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"pa-6",attrs:{"colspan":headers.length}},[_c('h4',[_vm._v(_vm._s(_vm.$t("companies.employees")))]),_c('v-data-table',{attrs:{"headers":_vm.detailsHeaders,"items":_vm.companyUsers,"loading":_vm.companyUsersLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name &&
                item.name.length > 0 &&
                item.surname &&
                item.surname.length > 0)?_c('div',{staticClass:"d-flex align-center"},[_c('StatusAvatar',{staticClass:"mr-2",attrs:{"userId":item.id,"showStatus":""}}),_c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))])],1):_vm._e()]}},{key:"item.role",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCompanyUserRole(item.role))+" ")]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","color":"active"}},[_vm._v(" "+_vm._s(_vm.getUserStatus(item.status))+" ")])]}}],null,true)})],1)]}}],null,true)})],1),_c('data-changes',{attrs:{"dialog":_vm.verifyDialog,"company":_vm.selectedCompany,"loading":_vm.loadingDialog},on:{"cancel":_vm.verifyDialogCancel,"rejectChanges":_vm.verifyDialogReject,"approveChanges":_vm.verifyDialogApprove}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }