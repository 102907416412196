let themesDomain = {}
switch (window.location.hostname) {
  case 'localhost':
    themesDomain = {
      testTenant1: 'default',
      testTenant2: 'custom'
    }
    break
  default:
    themesDomain = {
      stageTenant1: 'default',
      stageTenant2: 'custom',
      eth1: 'default',
      eth2: 'custom'
    }
    break
}

export { themesDomain }

export const themePaths = {
  custom: 'custom',
  default: 'default'
}
export const logoConfig = {
  default: {
    primary: 'SPEDIMO_logo.svg',
    secondary: 'SPEDIMO_logo_white.svg'
  },
  custom: {
    primary: 'custom_logo.svg',
    secondary: 'custom_logo_white.svg'
  }
}
export const faviconConfig = {
  default: 'static/img/default/favicon.png',
  custom: 'static/img/custom/favicon_custom.png',
  'custom/alert': 'static/img/custom/alert/favicon_red.png',
  'default/alert': 'static/img/default/alert/favicon_red.png'
}
