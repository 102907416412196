import moment from 'moment'
import vuetify from '@/plugins/vuetify'
import i18n from '@/i18n'
import * as soundTypes from '@/types/sound-types'
import store from '@/store'

export const adminRoleId = 99
export const ownerRoleId = 98
export const workerRoleId = 2
export const managerRoleId = 4
export const driverRoleId = 3
export const customerServiceRoleId = 1

export const getTokenData = () => {
  if (localStorage.getItem('token')) {
    const jwtData = localStorage.getItem('token').split('.')[1]
    const decodedJwtJsonData = decodeURIComponent(
      Array.prototype.map
        .call(atob(jwtData), function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    const decodedJwtData = JSON.parse(decodedJwtJsonData)
    return decodedJwtData
  }
  return {
    isInAdminRole: false,
    userId: '',
    userRoleId: '',
    userName: ''
  }
}

export const dateTime = (value) => {
  if (value) {
    return moment(value).format('DD.MM.YYYY HH:mm')
  }
}

export const dateFormat = (value) => {
  if (value) {
    return moment(value).format('DD.MM.YYYY')
  }
}

export const dateFormat2 = (value) => {
  if (value) {
    return moment(value).format('YYYY-MM-DD')
  }
}

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const getEnumDescription = function (enumList, value) {
  let list = []

  if (value === null) return ''

  if (enumList && enumList.length) {
    list = enumList.filter((item) => item.id === value)
  }

  return list.length > 0 ? list[0].description : ''
}

export const roles = {
  undefined: 0,
  user: 1,
  admin: +adminRoleId
}

export const isInRole = function (role) {
  return +this.$store.getters.userRoleId === role
}

export const fileUrl = (value) => {
  const tenantName = localStorage.getItem('tenantName')
  return `${process.env.VUE_APP_BASE}${tenantName}/uploads/${value}`
}

export const generateString = () => {
  return Math.random().toString(36).slice(-8)
}

export const contactPhoneNumber = '+48 000 000 000'
export const contactMail = 'kontakt@24tsl.pl'

export const changeLocale = async (locale, languages) => {
  if (languages.includes(locale)) {
    const lang = locale.slice(0, 2)
    vuetify.framework.lang.current = lang
    moment.locale(lang)
    i18n.locale = lang
    document.querySelector('html').lang = lang
    localStorage.setItem('lang', locale)
    await store.dispatch('enums')
  }
}

export const getInitials = (firstName, lastName) => {
  if (!firstName || firstName.length < 1 || !lastName || lastName.length < 1) {
    return ''
  }
  return `${firstName.charAt(0)}${lastName.charAt(0)}`
}

export const isVatNumberValid = (value) => {
  if (value) {
    const regex = /^[a-zA-Z\d]{8,14}$/
    const matches = regex.test(value)
    return matches
  }
}

export const departmentEnum = {
  customerService: 0,
  debtRecovery: 1,
  legalDepartment: 2
}

export const cloneObj = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const equalsObj = (objA, objB) => {
  return JSON.stringify(objA) === JSON.stringify(objB)
}

export const isFile = (file) => {
  return file instanceof File
}

export const containsSpecialChars = (string) => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
  return specialChars.test(string)
}

export const containsDigits = (string) => {
  return /\d/.test(string)
}

export const getSoundSource = (soundType) => {
  let src = []

  if (soundType === soundTypes.PRISTINE) {
    src = [require('@/assets/sounds/pristine.mp3')]
  } else if (soundType === soundTypes.JUNTOS) {
    src = [require('@/assets/sounds/juntos.mp3')]
  } else if (soundType === soundTypes.ELEGANT) {
    src = [require('@/assets/sounds/elegant.mp3')]
  } else if (soundType === soundTypes.EVENTUALLY) {
    src = [require('@/assets/sounds/eventually.mp3')]
  } else if (soundType === soundTypes.JUSTSAYING) {
    src = [require('@/assets/sounds/just-saying.mp3')]
  }
  return src
}

export const downloadFile = (file, response) => {
  const blob = new Blob([response])
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = file.name
  link.click()
  URL.revokeObjectURL(link.href)
}

export const downloadLocalFile = (file) => {
  const blob = new Blob([file])
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = file.name
  link.click()
  URL.revokeObjectURL(link.href)
}

export const getFileTypeFromName = (fileName) => {
  const extension = getFileExtensionFromName(fileName)
  if ('png jpg jpeg'.includes(extension)) {
    return 'IMAGE'
  } else if (extension.includes('pdf')) {
    return 'PDF'
  } else if (extension.includes('XLS')) {
    return 'XLS'
  } else {
    return 'OTHER'
  }
}

export const getFileExtensionFromName = (fileName) => {
  return fileName.split('.').pop()
}

export const defaultAcceptTypeFile = '.pdf, .jpg, .jpeg, .png'

export const maxCharInPhoneNumber = (value) => value && value.length > 14 ? i18n.t('validation.maxChar') : true
export const maxCharInZipCode = (value) => value && value.length > 10 ? i18n.t('validation.maxChar') : true

export const roundNumber = (value, fractionDigits = 3) => {
  return Number.parseFloat(value).toFixed(fractionDigits)
}

export const fractionDigitsValidation = (value, number) => {
  const str = String(value)
  if (!str) {
    return true
  }
  const strSplit = str.split('.')
  if (strSplit.length > 1) {
    const digits = strSplit[1]
    return digits.length <= number
  } else {
    return true
  }
}

export function shouldShowAddFreightButton (featureFlagList, useFeatureFlag, permissionActive) {
  const featureFlagEnabled = useFeatureFlag(featureFlagList.ADD_FREIGHT_LIMITED)
  return !featureFlagEnabled || permissionActive('Flag_AddFreight_Limited')
}
