<template>
  <div>
    <v-card class="pa-5">
      <div class="d-flex align-center mb-6">
        <h2 class="text-subtitle-1 font-weight-bold mr-4">
          {{ $t("users.title") }}
        </h2>
        <div>
          <SearchInput v-model="searchInputValue" />
        </div>
        <v-btn color="primary" dark class="ml-auto" @click="addNew">
          {{ $t("users.addNew") }}
        </v-btn>
      </div>
      <data-table
        ref="dataTable"
        :headers="filteredHeaders"
        endpoint="/UserManagement/GetUsers"
        serverPagination
        :search="searchInputValue"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <StatusAvatar
              :userId="item.id"
              showStatus
              class="mr-2"
            ></StatusAvatar>
            <span>{{ item.name }} {{ item.surname }}</span>
          </div>
        </template>
        <template v-slot:[`item.customerServiceRole`]="{ item }">
          {{ getUserRole(item.customerServiceRole) }}
        </template>
        <template v-slot:[`item.rating`]="{ item }">
          <div class="d-flex align-center">
            <v-rating
              v-model="item.averageRate"
              background-color="priamry"
              color="priamry"
              x-small
            ></v-rating>
            <p class="mb-0 ml-1">({{ item.reviewsCount }})</p>
          </div>
        </template>
        <template v-slot:[`item.chatIcon`]="{ item }">
          <v-btn
            v-if="$store.getters.userCommonData.id !== item.id"
            icon
            @click="startConversationWithUser(item.id)"
          >
            <v-icon class="w-50" color="info">mdi-chat-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="d-flex align-center">
            <v-chip
              v-if="item.profileUpdated"
              small
              label
              color="stateChange"
            >
              {{ $t("users.update") }}
            </v-chip>
          <v-chip v-else-if="item.status == 1" class="ma-2" small label color="active">
          {{ getUserStatus(item.status) }}
        </v-chip>
        <v-chip v-else class="ma-2" small label color="red">
          {{ getUserStatus(item.status) }}
        </v-chip>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="goToProfile(item)">
                <v-list-item-title>{{
                  $t("users.goToProfile")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="edit(item)">
                <v-list-item-title>{{
                  $t("users.editProfile")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="verify(item)"
                v-if="item.profileUpdated"
              >
                <v-list-item-title>{{
                  $t("users.verifyProfile")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="reset(item)">
                <v-list-item-title>{{
                  $t("users.resetPassword")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="block(item)" v-if="(item.id !== $store.getters.userCommonData.id || item.role !== adminRoleId) && item.status !== 2">
                <v-list-item-title>{{
                  $t("users.blockUser")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="unblock(item)" v-if="(item.id !== $store.getters.userCommonData.id || item.role !== adminRoleId) && item.status === 2">
                <v-list-item-title>{{
                  $t("users.unblockUser")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.id !== $store.getters.userCommonData.id" link @click="openSetRoleEmployeeDialog(item)">
                <v-list-item-title>
                  {{ $t('users.dialogs.setRole.title')}}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getUsers">{{
            $t("users.slotNoData")
          }}</v-btn>
        </template>
      </data-table>
    </v-card>
    <add-new
      :dialog="addNewDialog"
      :loading="loadingDialog"
      @close="addNewDialogClose"
      @submit="addNewDialogSubmit"
    ></add-new>
    <edit-profile
      :dialog="editDialog"
      :user="selectedUser"
      :loading="loadingDialog"
      @close="editDialogClose"
      @submit="editDialogSubmit"
    ></edit-profile>
    <profile-changes
      :dialog="verifyDialog"
      :user="selectedUser"
      :loading="loadingDialog"
      @reject="verifyDialogReject"
      @approve="verifyDialogApprove"
    ></profile-changes>
    <block-user
      :dialog="blockDialog"
      :user="selectedUser"
      :loading="loadingDialog"
      @close="blockDialogClose"
      @submit="blockDialogSubmit"
    ></block-user>
    <unblock-user
      :dialog="unblockDialog"
      :user="selectedUser"
      :loading="loadingDialog"
      @cancel="unblockDialogClose"
      @unblock="unblockDialogSubmit"
    ></unblock-user>
    <reset-password
      :dialog="resetDialog"
      :user="selectedUser"
      :loading="loadingDialog"
      @cancel="resetDialogCancel"
      @reset="resetDialogReset"
    ></reset-password>
    <v-dialog v-model="setRoleDialog" persistent max-width="500px">
      <v-card class="py-4">
        <v-card-title>{{ $t('users.dialogs.setRole.title')}}</v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="setRoleForm" :disabled="loadingDialog">
              <v-row>
                <v-col class="pa-0" cols="12">
                   <v-select
                      v-model="setRoleValue"
                      :items="roles"
                      item-value="id"
                      item-text="description"
                      class="text-left"
                      outlined
                      dense
                      persistent-hint
                    ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer></v-spacer>
          <v-btn
            color="secodary"
            outlined
            depressed
            class="mr-2"
            :disabled="loadingDialog"
            @click="closeSetRoleDialog"
          >
            {{ $t('common.cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :loading="loadingDialog"
            @click="setRoleEmployee"
          >
            {{ $t('common.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Service from '@/services'
import { adminRoleId, getEnumDescription } from '@/utils/helpers'
import SearchInput from '@/components/search/SearchInput.vue'
import ProfileChanges from './dialogs/ProfileChanges.vue'
import BlockUser from './dialogs/BlockUser.vue'
import ResetPassword from '../../components/dialogs/ResetUserPassword.vue'
import UnblockUser from './dialogs/UnblockUser.vue'
import AddNew from './dialogs/AddNew.vue'
import EditProfile from './dialogs/EditProfile.vue'
import StatusAvatar from '../../components/StatusAvatar.vue'
import * as roleTypes from '@/types/role-types'
import { startConversation } from '@/signalr/chat'
import { FEATURE_FLAGS } from '@/utils/featureFlagList'

export default {
  name: 'Employees',
  components: {
    SearchInput,
    ProfileChanges,
    BlockUser,
    ResetPassword,
    UnblockUser,
    AddNew,
    EditProfile,
    StatusAvatar
  },
  data () {
    return {
      total: 0,
      users: [],
      loading: true,
      options: {
        itemsPerPage: 10
      },
      searchInputValue: '',
      headers: [
        {
          text: this.$t('users.name'),
          align: 'start',
          value: 'name'
        },
        {
          text: this.$t('users.email'),
          align: 'start',
          value: 'email'
        },
        {
          text: this.$t('users.role'),
          align: 'start',
          value: 'customerServiceRole'
        },
        {
          text: this.$t('users.state'),
          value: 'status',
          align: 'start'
        },
        {
          text: this.$t('users.rating'),
          value: 'rating',
          align: 'start'
        },
        {
          text: '',
          value: 'chatIcon',
          sortable: false
        },
        {
          text: '',
          align: 'end',
          width: '75',
          value: 'actions',
          sortable: false
        }
      ],
      selectedUser: {},
      loadingDialog: false,
      addNewDialog: false,
      verifyDialog: false,
      editDialog: false,
      blockDialog: false,
      unblockDialog: false,
      resetDialog: false,
      setRoleDialog: false,
      setRoleEmployeeId: null,
      setRoleValue: null,
      adminRoleId
    }
  },
  watch: {
    options: {
      handler () {
        this.getUsers()
      },
      deep: true
    }
  },
  computed: {
    roles () {
      return this.$store.getters.enums.roles.filter((el) => el.id === roleTypes.admin || el.id === roleTypes.customerServiceUser)
    },
    pageCount () {
      return Math.ceil(this.total / this.options.itemsPerPage)
    },
    customerServiceRoleTypes () {
      return this.$store.getters.enums.customerServiceRoleTypes
    },
    userStatus () {
      return this.$store.getters.enums.userStatus
    },
    featureFlagList () {
      return FEATURE_FLAGS
    },
    filteredHeaders () {
      if (!this.$useFeatureFlag(this.featureFlagList.DISABLE_REVIEWS)) {
        return this.headers
      } else {
        return this.headers.filter(item => item.value !== 'rating')
      }
    }
  },
  methods: {
    openSetRoleEmployeeDialog (item) {
      this.setRoleDialog = true
      this.setRoleEmployeeId = item.id
      this.setRoleValue = item.role
    },
    closeSetRoleDialog () {
      this.setRoleDialog = false
    },
    async setRoleEmployee () {
      this.loadingDialog = true
      try {
        await Service.post('UserManagement/SetRole', {
          userId: this.setRoleEmployeeId,
          role: this.setRoleValue
        })
        await this.getUsers()
      } catch (e) {
      } finally {
        this.loadingDialog = false
        this.closeSetRoleDialog()
      }
    },
    async getUsers () {
      await this.$refs.dataTable.getData()
    },
    getUserRole (role) {
      return getEnumDescription(this.customerServiceRoleTypes, role)
    },
    getUserStatus (status) {
      return getEnumDescription(this.userStatus, status)
    },
    addNew () {
      this.addNewDialog = true
    },
    addNewDialogClose () {
      this.addNewDialog = false
    },
    async addNewDialogSubmit (user) {
      this.loadingDialog = true
      try {
        await Service.post('UserManagement/AddUser', user)

        await this.getUsers()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.add')
        )
        this.addNewDialog = false
      } catch (e) {}
      this.loadingDialog = false
    },
    async edit (user) {
      try {
        const { data } = await Service.getById(
          '/UserManagement/GetUser',
          user.id
        )
        this.selectedUser = data
      } catch (e) {}
      this.editDialog = true
    },
    editDialogClose () {
      this.editDialog = false
    },
    async editDialogSubmit (user) {
      this.loadingDialog = true
      try {
        await Service.put('/UserManagement/UpdateUser', {
          userId: user.id,
          ...user
        })
        this.editDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.edited')
        )
        await this.getUsers()
      } catch (e) {}
      this.loadingDialog = false
    },
    async verify (user) {
      try {
        const { data } = await Service.getById(
          '/UserManagement/GetUser',
          user.id
        )
        this.selectedUser = data
      } catch (e) {}
      this.verifyDialog = true
    },
    async verifyDialogReject () {
      this.loadingDialog = true
      try {
        await Service.post('/UserManagement/RejectUserChanges', {
          userId: this.selectedUser.id
        })
        this.verifyDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.reject')
        )
        this.getUsers()
      } catch (e) {}
      this.loadingDialog = false
    },
    async verifyDialogApprove () {
      this.loadingDialog = true
      try {
        await Service.post('/UserManagement/ApproveUserChanges', {
          userId: this.selectedUser.id
        })
        this.verifyDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.approve')
        )
        this.getUsers()
      } catch (e) {}
      this.loadingDialog = false
    },
    block (user) {
      this.selectedUser = user
      this.blockDialog = true
    },
    blockDialogClose () {
      this.blockDialog = false
    },
    async blockDialogSubmit (reason) {
      this.loadingDialog = true
      try {
        await Service.post('/UserManagement/Block', {
          userId: this.selectedUser.id,
          blockReason: reason
        })
        this.blockDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.blockSubmit')
        )
        this.getUsers()
      } catch (e) {}
      this.loadingDialog = false
    },
    unblock (user) {
      this.selectedUser = user
      this.unblockDialog = true
    },
    unblockDialogClose () {
      this.unblockDialog = false
    },
    async unblockDialogSubmit () {
      this.loadingDialog = true
      try {
        await Service.post('/UserManagement/Unblock', {
          userId: this.selectedUser.id
        })
        this.unblockDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.unblockSubmit')
        )
        this.getUsers()
      } catch (e) {}
      this.loadingDialog = false
    },
    reset (user) {
      this.selectedUser = user
      this.resetDialog = true
    },
    resetDialogCancel () {
      this.resetDialog = false
    },
    async resetDialogReset () {
      this.loadingDialog = true
      try {
        await Service.put('/UserManagement/EnforcePasswordReset', {
          userId: this.selectedUser.id
        })
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.reset')
        )
        this.resetDialog = false
      } catch (e) {}
      this.loadingDialog = false
    },
    goToProfile (item) {
      this.$router.push({ name: 'CustomerServiceUserProfile', params: { userId: item.id } })
    },
    startConversationWithUser (userId) {
      startConversation(userId)
    }
  }
}
</script>
<style scoped>

.stateChange {
  color: var(--v-stateChange);
}
.active {
  color: var(--v-primary);
}
</style>
